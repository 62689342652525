/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Text, jsx } from 'theme-ui'
import { Check } from 'iconic-react'

const IconText = ({ text }) => (
  <Flex
    marginY={2}
    sx={{
      alignItems: 'center',
    }}
  >
    <Flex
      sx={{
        minWidth: '40px',
        height: '40px',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'summerSky',
        marginRight: 2,
      }}
    >
      <Check size={30} />
    </Flex>
    <Text variant="detail">{text}</Text>
  </Flex>
)

export default IconText
