/* eslint-disable quotes */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Box, Text, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'
import { Check } from 'iconic-react'

import SEO from '../components/Seo'
import C from '../constants'
import PageLayout from '../components/PageLayout'
import BannerWrapper from '../components/BannerWrapper'
import BackButton from '../components/BackButton'
import TextBlock from '../components/TextBlock'
import Banner from '../components/Banner'
import IconText from '../components/IconText'
import FreeTrialBanner from '../components/FreeTrialBanner'
import Link from '../components/Link'

const GettingStarted = () => {
  const { t } = useTranslation()
  return (
    <PageLayout>
      <SEO title="Getting Started" />
      <BannerWrapper>
        <BackButton />
        <Box p={[3, 3, 4, 4]}>
          <TextBlock
            heading={t('GettingStartedPage:pageHeading')}
            headingAsH1={true}
          />
        </Box>
        <Banner
          imageName="holding-tablet-04"
          imageAlt={t('ImageAlts:imageAlt1')}
          textOrientation="left"
        >
          <Box sx={{ flexDirection: 'colum' }}>
            <TextBlock heading={t('GettingStartedPage:firstBannerHeading')} />
            <Flex
              marginY={2}
              sx={{
                alignItems: 'center',
              }}
            >
              <Flex
                sx={{
                  minWidth: '40px',
                  height: '40px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'summerSky',
                  marginRight: 2,
                }}
              >
                <Check size={30} />
              </Flex>
              <Link
                href={C.links.signupLink}
                text={t('Global:signUp')}
                isFooter={false}
                isInternal={false}
              />
              <Text variant="detail" sx={{ paddingLeft: 2 }}>
                {t('GettingStartedPage:listItem1')}
              </Text>
            </Flex>
            <IconText text={t('GettingStartedPage:listItem2')} />
            <IconText text={t('GettingStartedPage:listItem3')} />
            <IconText text={t('GettingStartedPage:listItem4')} />
            <IconText text={t('GettingStartedPage:listItem5')} />
          </Box>
        </Banner>
        <Banner
          imageName="tablet-screen-booking-screen"
          imageAlt={t('ImageAlts:schedulingPageAlt2')}
          textOrientation="right"
        >
          <Box sx={{ flexDirection: 'colum' }}>
            <TextBlock heading={t('GettingStartedPage:secondBannerHeading')} />
            <IconText text={t('GettingStartedPage:listItem6')} />
            <IconText text={t('GettingStartedPage:listItem7')} />
            <IconText text={t('GettingStartedPage:listItem8')} />
            <IconText text={t('GettingStartedPage:listItem9')} />
            <IconText text={t('GettingStartedPage:listItem10')} />
            <IconText text={t('GettingStartedPage:listItem11')} />
            <IconText text={t('GettingStartedPage:listItem12')} />
          </Box>
        </Banner>
        <Banner
          imageName="holding-tablet-04"
          imageAlt={t('ImageAlts:imageAlt1')}
          textOrientation="left"
        >
          <Box sx={{ flexDirection: 'colum' }}>
            <TextBlock heading={t('GettingStartedPage:thirdBannerHeading')} />
            <IconText text={t('GettingStartedPage:listItem13')} />
            <IconText text={t('GettingStartedPage:listItem14')} />
            <IconText text={t('GettingStartedPage:listItem15')} />
            <IconText text={t('GettingStartedPage:listItem16')} />
          </Box>
        </Banner>
        <Banner
          imageName="tablet-screen-services-list"
          imageAlt={t('ImageAlts:servicesPageAlt2')}
          textOrientation="right"
        >
          <Box sx={{ flexDirection: 'colum' }}>
            <TextBlock heading={t('GettingStartedPage:fourthBannerHeading')} />
            <IconText text={t('GettingStartedPage:listItem17')} />
            <IconText text={t('GettingStartedPage:listItem18')} />
            <IconText text={t('GettingStartedPage:listItem19')} />
          </Box>
        </Banner>
        <Banner
          imageName="holding-tablet-04"
          imageAlt={t('ImageAlts:imageAlt1')}
          textOrientation="left"
        >
          <Box sx={{ flexDirection: 'colum' }}>
            <TextBlock heading={t('GettingStartedPage:fifthBannerHeading')} />
            <IconText text={t('GettingStartedPage:listItem20')} />
            <IconText text={t('GettingStartedPage:listItem21')} />
            <IconText text={t('GettingStartedPage:listItem22')} />
            <IconText text={t('GettingStartedPage:listItem23')} />
            <IconText text={t('GettingStartedPage:listItem24')} />
            <IconText text={t('GettingStartedPage:listItem25')} />
          </Box>
        </Banner>
        <Banner
          imageName="tablet-screen-pos"
          imageAlt={t('ImageAlts:imageAlt4')}
          textOrientation="right"
        >
          <Box sx={{ flexDirection: 'colum' }}>
            <TextBlock heading={t('GettingStartedPage:fourthBannerHeading')} />
            <IconText text={t('GettingStartedPage:listItem26')} />
            <IconText text={t('GettingStartedPage:listItem27')} />
            <IconText text={t('GettingStartedPage:listItem28')} />
            <IconText text={t('GettingStartedPage:listItem29')} />
          </Box>
        </Banner>
      </BannerWrapper>
      <FreeTrialBanner />
    </PageLayout>
  )
}

export default GettingStarted
